<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          src="/logo.png"
          alt="InitiativeCode Logo"
          width="100"
          height="35"
          class="d-inline-block align-text-top"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav ms-auto">
          <router-link class="nav-link" to="/" aria-current="page"
            >Home</router-link
          >
          <router-link class="nav-link" to="/about">About Us</router-link>
          <router-link class="nav-link" to="/projects">Projects</router-link>

          <!-- Products Dropdown Menu -->
          <div class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Products
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/products/communication"
                  >Communication</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/products/akis-queue"
                  >Queue System</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/products/solar-panels"
                  >Solar Panels</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/products/inverters"
                  >Inverters</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/products/batteries"
                  >Batteries</router-link
                >
              </li>
            </ul>
          </div>

          <router-link class="nav-link" to="/contact">Contact Us</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>
/* Navbar Styles */
.navbar {
  background-color: #ffffff;
  padding: 1rem 0;
  transition: background-color 0.3s ease;
  font-family: "Poppins", sans-serif; /* Professional Font */
}

.navbar-nav .nav-link {
  color: #333333;
  padding: 0.75rem 1rem;
  transition: color 0.3s ease;
  font-weight: 500; /* Balanced font weight */
  font-size: 1rem;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: #007bff;
}

.navbar-brand img {
  max-height: 40px;
}

/* Dropdown Styles */
.dropdown-menu {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease;
  background-color: #ffffff;
}

.dropdown-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

/* Navbar Toggler */
.navbar-toggler {
  border: none;
  outline: none;
}

@media (max-width: 992px) {
  .navbar-collapse {
    background-color: #ffffff;
    padding: 1rem;
    border-top: 1px solid #e5e5e5;
  }
}
</style>
